import React from "react"
// import ReactHlsPlayer from "react-hls-player"
import Hls from "hls.js"
import { Waypoint } from "react-waypoint"
const UtilVideo = ({ id, thumbnail, className, aspect }) => {
  const idRef = React.useRef(id)
  const playerRef = React.createRef()
  // const videoRef = React.useRef(playerRef.current)
  React.useEffect(() => {
    const playback = "https://stream.mux.com/" + idRef.current + ".m3u8"
    // const playerRef = React.createRef()
    let video = playerRef.current
    // console.log(video)
    if (Hls.isSupported()) {
      let hls = new Hls()
      hls.loadSource(playback)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, function () {})
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      // video.setAttribute("anonymous")
      video.src = playback
    }
  }, [playerRef])

  const playVideo = () => {
   

    playerRef.current.play()
  }
  const pauseVideo = () => {
  
    playerRef.current.pause()
  }
  const poster =
    "https://image.mux.com/" +
    id +
    "/thumbnail.jpg?time=" +
    (thumbnail ? thumbnail : 0)
  function toggleVideo(e) {
    e.preventDefault()
    if (playerRef.current.paused) {
      playVideo()
    } else {
      pauseVideo()
    }
  }

  return (
    <Waypoint
      onEnter={() => {
        playVideo()
      }}
      // onLeave={() => {
      //   pauseVideo()
      // }}
    >
      <video
        loop
        playsInline
        muted
        ref={playerRef}
        src={`https://stream.mux.com/${id}.m3u8`}
        onClick={(e) => toggleVideo(e)}
        poster={poster}
        style={{ aspectRatio: `${aspect}` }}
        className={className}
      ></video>
      {/* <div className="w-full h-full">
        <ReactHlsPlayer
          autoPlay={true}
          onClick={() => toggleVideo()}
          playerRef={playerRef}
          src={playback}
          muted="muted"
          className={className}
        />
      </div> */}
    </Waypoint>
  )
}

export default UtilVideo
