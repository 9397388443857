import React from "react"
import { Link } from "gatsby"

import UtilImage from "../util/UtilImage"
const ImageButtons = ({ section }) => {
  return (
    <div className="top-0 hidden w-full bg-white divide-x divide-gray-200 md:flex hover:divide-transparent">
      {section.buttons.map((button, index) => {
        const image = button.image.asset.gatsbyImageData

        const link =
          button.link?.linkObject?._type === "collection"
            ? `/collection/${button.link.linkObject.slug.current}`
            : button.link?.linkObject?._type === "page"
            ? `/${button.link.linkObject.slug.current}`
            : button.link?.linkObject?._type === "product"
            ? `/store/${button.link.linkObject.store.slug.current}`
            : button.link?._type === "aboutLink"
            ? `${button.link.slug.current}`
            : "/"

        return (
          <Link
            key={button._key}
            to={link}
            className={`relative flex-1 border-gray-200 button aspect-w-16 aspect-h-5 min-w-1/2 group hover:border-transparent `}
          >
            <UtilImage
              image={image}
              className="absolute top-0 left-0 w-full h-full transition duration-300 opacity-0 group-hover:opacity-100 picture-cover"
            ></UtilImage>
            <div
              className="absolute inset-0 p-3 px-4 leading-tight transition duration-300 text-large group-hover:opacity-0"
              // to={button.link?.linkObject?.slug?.current}
            >
              {button.title}
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default ImageButtons
