import React from "react"

const Plus = ({ hover, checkWhite }) => {
  return (
    <svg
      className={`w-full`}
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="transition-all duration-150 ease-in"
        fill={checkWhite && hover ? "white" : "transparent"}
        stroke={checkWhite && hover ? "white" : "transparent"}
        d="M30 2.5C14.8122 2.5 2.5 14.8122 2.5 30C2.5 45.1878 14.8122 57.5 30 57.5C45.1878 57.5 57.5 45.1878 57.5 30C57.5 14.8122 45.1878 2.5 30 2.5ZM0 30Z"
      />

      <path
        className="transition-all duration-150 ease-in"
        fill={checkWhite && hover ? "white" : "currentColor"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 2.5C14.8122 2.5 2.5 14.8122 2.5 30C2.5 45.1878 14.8122 57.5 30 57.5C45.1878 57.5 57.5 45.1878 57.5 30C57.5 14.8122 45.1878 2.5 30 2.5ZM0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
      />
      <path
        className="transition-all duration-150 ease-in"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={checkWhite && hover ? "black" : "currentColor"}
        d="M41.0882 28.73L32.617 20.2747L34.383 18.5053L45.8774 29.9779L34.3951 41.4926L32.6249 39.7274L41.0983 31.23H14.11V28.73H41.0882Z"
      />
    </svg>
  )
}

export default Plus
