import React from "react"
import LinkPlus from "../link/LinkPlus"
import UtilImage from "../util/UtilImage"
import "./campaign.scss"
const Campaign = ({ section, active }) => {
  const image = section.image.asset.gatsbyImageData
  const link =
    section.link?._type === "collection"
      ? `/collection/${section.link?.slug.current}`
      : section.link?._type === "page"
      ? `/${section.link.slug.current}`
      : section.link?._type === "product"
      ? `/store/${section.link.store.slug.current}`
      : section.link?._type === "aboutLink"
      ? `${section.link.slug.current}`
      : "/"

  return (
    <div className="relative top-0 w-full h-full overflow-hidden ">
      {section.title && (
        <LinkPlus
          to={link}
          title={section.title}
          className="absolute top-0 z-10 flex items-center justify-between w-full text-white bg-black heading header"
        ></LinkPlus>
      )}
      <UtilImage
        image={image}
        className={`absolute top-0 h-screen transition duration-300 picture-cover  `}
      ></UtilImage>
    </div>
  )
}

export default Campaign
