import React, { useState } from "react"
import { Link } from "gatsby"
import ArrowCircle from "../svg/arrowCircle"
import ArrowCircleInvert from "../svg/arrowCircleInvert"
const LinkPlus = ({ to, title, className }) => {
  const [hover, setHover] = useState(false)

  const checkWhite = className.includes("text-white")

  return (
    <Link
      to={to}
      className={`flex justify-between group h-70px lg:h-90px ${className}`}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span className="leading-none -mt-0.5">{title}</span>
      <figure className=" w-35px h-35px lg:w-60px lg:h-60px">
        <span className="group-hover:hidden">
          <ArrowCircle />
        </span>
        <span className="hidden group-hover:block">
          <ArrowCircleInvert hover={hover} checkWhite={checkWhite} />
        </span>
      </figure>
    </Link>
  )
}

export default LinkPlus
