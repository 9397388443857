import React from "react"
import UtilVideo from "../util/UtilVideo"
import UtilImage from "../util/UtilImage"
import ProductGrid from "../product/ProductGrid"
const CampaignGrid = ({ section }) => {
  return (
    <div className="relative flex flex-col md:flex-row">
      <div className="top-0 z-20 flex-1 max-h-screen overflow-hidden md:sticky pt-9 -mt-9">
        <div className="relative w-full h-full overflow-hidden bg-black">
          {section.video ? (
            <UtilVideo
              id={section.video.asset.playbackId}
              thumbnail={section.video.thumbnail}
              className="object-cover object-center w-full h-full"
            />
          ) : (
            section.image && (
              <UtilImage
                image={section.image.asset.gatsbyImageData}
                className="absolute top-0 h-full picture-cover"
              />
            )
          )}
        </div>
      </div>
      <div className="flex-1 -mb-0.5 bg-white">
        <ProductGrid products={section.products.products} grid="2" />
      </div>
    </div>
  )
}

export default CampaignGrid
