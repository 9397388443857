import React from "react"

const Plus = ({ hover, checkWhite }) => {
  return (
    <svg
      className="w-full h-auto"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z"
        // fill="black"
        fill={checkWhite && hover ? "white" : "black"}
      />
      <path
        d="M41.0882 28.73L32.617 20.2747L34.383 18.5053L45.8774 29.9779L34.3951 41.4926L32.6249 39.7274L41.0983 31.23H14.11V28.73H41.0882Z"
        // fill="white"
        fill={checkWhite && hover ? "black" : "white"}
      />
    </svg>
  )
}

export default Plus
