
import React from 'react'
// import Swiper core and required modules
import { Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import "./carousel.scss"

import { GatsbyImage } from 'gatsby-plugin-image';
const Carousel = ({ section }) => {

    // console.log(section)
    return (
        <div className="relative flex w-full h-full bg-white">
            <Swiper
                modules={[Navigation]}
                slidesPerView={'auto'}
                navigation

            >
                {
                    section.images && section.images.map((image, index) => {
                        return (
                            <SwiperSlide key={index} className='flex justify-center h-screen'>
                                <GatsbyImage image={image.asset.gatsbyImageData} className='object-center w-full h-full' alt="" />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
}

export default Carousel
