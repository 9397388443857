import React, { useRef, useEffect } from "react"
import AccordionColumn from "./AccordionColumn"
import { HeaderContext } from "../../context/header-context"
import { useLocation } from "@reach/router"

import "./accordion.scss"
const Accordion = ({ section, clientSection }) => {
  const [toggle, setToggle] = React.useState(false)
  const { activeAccordion, setActiveAccordion } = React.useContext(
    HeaderContext
  )
  const title = section && section.title && section.title.toLowerCase()
  const location = useLocation()

  const active = activeAccordion === section._key && toggle

  const toggleButton = () => {
    if (active) {
      setToggle(false)
    } else {
      setToggle(true)
    }
    setActiveAccordion(section._key)
  }
  useEffect(() => {
    if (location.hash) {
      const localTitle = location.hash.replace("#", "").replace(/-/g, " ")
      if (localTitle === title) {
        setToggle(true)
        setActiveAccordion(section._key)
      }
    }
  }, [location, section, setActiveAccordion, title])

  const myRef = useRef(null)


  return (
    <section className="-mt-px">
      <header
        className={`flex items-center justify-between padding h-70px lg:h-90px text-large leading-none group transition-colors duration-300 bg-white border-t  border-gray-200 cursor-pointer  line-link hover:text-black ${active ? "selected text-black" : "text-gray-200"
          }`}
        ref={myRef}
        onClick={() => toggleButton()}
        onKeyDown={() => toggleButton()}
        role="button"
        tabIndex={0}
      >
        <span>{section.title}</span>
        <span
          className={`${active ? "opacity-100" : "opacity-0"
            } transition text-gray-200 duration-300 hidden md:block group-hover:text-black line-link`}
        >
          <span>Close</span>
        </span>
      </header>
      <div
        className={`border-t border-gray-200 transition-all  duration-1000    ${active ? "max-h-auto" : "max-h-0"
          }`}
      >
        <div
          className={`w-full h-full md:flex ${active ? "opacity-100" : "opacity-0"
            }`}
        >
          {section.accordionColumns &&
            section.accordionColumns
              .filter(column => column)
              .map((column, index) => {
                return (
                  <AccordionColumn
                    key={column._key}
                    column={column}
                    clientContent={
                      clientSection && clientSection?.columns[index]
                    }
                  ></AccordionColumn>
                )
              })}
        </div>
      </div>
    </section>
  )
}

export default Accordion
