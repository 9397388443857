import React from "react"
import LinkPlus from "../link/LinkPlus"
const Title = ({ section }) => {
  const title = section.sanityTitle.linkObject
  const link =
    title?._type === "collection"
      ? `/collection/${title.slug.current}`
      : title?._type === "page"
      ? `/${title.slug.current}`
      : title?._type === "product"
      ? `/store/${title.store.slug.current}`
      : title?._type === "aboutLink"
      ? `${title.slug.current}`
      : "/"

  return (
    section.sanityTitle.title && (
      <LinkPlus
        to={link}
        title={section.sanityTitle.title}
        className="items-center transform bg-white border-gray-200 padding text-large"
      ></LinkPlus>
    )
  )
}

export default Title
