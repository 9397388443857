import React from "react"
import TextTitle from "./TextTitle"
import RichText from "../text/RichText"
import UtilImage from "../util/UtilImage"
import { sectionCell, content } from "./grid.module.scss"

const Grid = ({ section, clientSection }) => {
  function generateSlug(title) {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")
  }
  return (
    <section className={` overflow-hidden`}>
      {section.title && <TextTitle title={section.title}></TextTitle>}
      <div className={`grid pb-px md:grid-cols-${section.columns}`}>
        {section.cells.map((cell, index) => {
          return (
            <div
              key={cell._key}
              className={`relative flex flex-col items-stretch -mb-px -mr-px  ${sectionCell} justify-stretch 
            col-span-${cell.colspan} row-span-${cell.rowspan}  ${cell.colspan === 2 && cell.rowspan !== 2
                  ? "aspect-w-6 aspect-h-4"
                  : "aspect-w-3 aspect-h-4"
                }`}
            >
              {cell._type === "cell" ? (
                <div className={`flex flex-col p-15px md:p-20px ${content}`}>
                  <span
                    className="absolute block -mt-40"
                    id={cell.title ? generateSlug(cell.title) : ""}
                  ></span>
                  <header className="flex justify-between">
                    <span>{cell.title}</span>
                    {cell.subtitle && <span>{cell.subtitle}</span>}
                  </header>
                  <div className="leading-tight text-md md:text-xl mt-15px md:mt-20px">
                    {clientSection && clientSection.cells[index].content && (
                      <RichText blocks={clientSection.cells[index].content} />
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col p-8px">
                  <div className="relative flex-1">
                    <UtilImage
                      image={cell.image.asset.gatsbyImageData}
                      className="absolute top-0 left-0 w-full h-full picture-cover"
                    ></UtilImage>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Grid
