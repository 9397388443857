import React from "react"

// So then need to import them out of gsap/all anyway
// This is before refactoring to new syntax, tweens are still same format as v2
// import ScrollMagic from "scrollmagic"

import { TweenMax, TimelineMax } from "gsap/all"

import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
import Accordion from "./Accordion"
import Campaign from "../section/Campaign"
import ImageButtons from "../section/ImageButtons"
import CampaignGrid from "../section/CampaignGrid"
import Products from "../section/Products"
import Copy from "../section/Copy"
import Title from "../section/Title"
import Grid from "../section/Grid"
import Carousel from "../section/Carousel"

import "./section.scss"
const ScrollMagic =
  typeof window !== `undefined` ? require("scrollmagic") : null
if (typeof window !== `undefined`) {
  ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)
}

const Section = ({ sections, clientSections }) => {
  // const dynamic = () => {
  //   const isBrowser = typeof window !== "undefined"
  //   if (isBrowser) {
  //     return window.innerHeight / 1.5
  //   }
  // }

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      function startAnimation() {
        const pins = document.querySelectorAll(".pin")
        const scrollmagic =
          typeof window !== `undefined` ? new ScrollMagic.Controller() : null

        pins.forEach(element => {
          const scene = new ScrollMagic.Scene({
            triggerElement: element,
            duration: "200%",
            triggerHook: 0,
          }).setPin(element, { pushFollowers: false })
          const slide = TweenMax.to(element.querySelector("a"), 1, {
            transform: "translate3d(0,-100%,0)",
            yPercent: 0,
          })
          const slideDown = TweenMax.to(element.querySelector("a"), 1, {
            transform: "translate3d(0,0,0)",
            yPercent: 0,
          })
          const scene2 = new ScrollMagic.Scene({
            triggerElement: element,
            duration: "25%",
            offset: window.innerHeight * 1.25,
            triggerHook: 1,
          }).setTween([slideDown])
          const height =
            element.querySelector("a").offsetHeight +
            document.querySelector(".sticky").offsetHeight

          const scene3 = new ScrollMagic.Scene({
            triggerElement: element,
            duration: height * 1.45,
            offset: window.innerHeight * 1.5 - height,
            triggerHook: 0,
          }).setTween([slide])

          scrollmagic.addScene(scene)
          scrollmagic.addScene(scene2)
          scrollmagic.addScene(scene3)
        })
        const copy = document.querySelectorAll(".copy-scroll")
        copy.forEach(element => {
          const copy = new ScrollMagic.Scene({
            triggerElement: element,
            duration: "50%",
            triggerHook: 0,
          }).setPin(element, { pushFollowers: true })

          const slide = TweenMax.to(
            element.querySelector(".copy-container"),
            1,
            {
              transform: "translate3d(0,-50%,0)",
              yPercent: 0,
            }
          )
          const copyScroll = new ScrollMagic.Scene({
            triggerElement: element,
            duration: "50%",
            triggerHook: 0,
          }).setTween([slide])
          scrollmagic.addScene(copy)
          scrollmagic.addScene(copyScroll)
        })
      }
      return startAnimation()
    }
  }, [])

  // console.log(clientSections)
  return (
    sections && (
      <div>
        {sections.map((section, index) => {
          const copy = section._type === "copy" ? true : false
          const campaignFull = section._type === "campaignFull" ? true : false
          const imageButtons = section._type === "imageButtons" ? true : false
          const campaignGrid = section._type === "campaignGrid" ? true : false
          const products = section._type === "productSection" ? true : false
          const title = section._type === "title" ? true : false
          const accordion = section._type === "accordion" ? true : false
          const grid = section._type === "grid" ? true : false
          const carousel = section._type === "carousel" ? true : false
          return (
            <section
              key={section._key ? section._key : section._id}
              className={`${section.show
                ? !section.show.mobile && !section.show.desktop
                  ? "hidden"
                  : !section.show.desktop
                    ? "md:hidden"
                    : !section.show.mobile
                      ? "hidden md:block"
                      : ""
                : ""
                } h-full ${campaignFull ? "overflow-hidden bg-black" : ""}`}
            >
              <div
                className={` ${campaignFull
                  ? "pin top-0 pt-45px md:pt-30px -mt-45px md:-mt-30px h-2-screen campaign"
                  : copy
                    ? "relative copy-scroll pt-45px md:pt-30px -mt-45px md:-mt-30px h-screen "
                    : " relative"
                  }`}
              >
                {copy && (
                  <Copy
                    section={section}
                    clientSection={clientSections && clientSections[index]}
                  />
                )}
                {campaignFull && (
                  <Campaign section={section} active={campaignFull} />
                )}
                {imageButtons && <ImageButtons section={section} />}
                {campaignGrid && <CampaignGrid section={section} />}
                {products && <Products section={section} />}
                {title && <Title section={section} />}
                {accordion && (
                  <Accordion
                    section={section}
                    clientSection={clientSections && clientSections[index]}
                  />
                )}
                {grid && (
                  <Grid
                    section={section}
                    clientSection={clientSections && clientSections[index]}
                  />
                )}
                {carousel && <Carousel section={section} />}
              </div>
            </section>
          )
        })}
      </div>
    )
  )
}

export default Section
