import React from "react"
import RichText from "../text/RichText"
import UtilImage from "../util/UtilImage"

const AccordionColumn = ({ column, clientContent }) => {
  // console.log(clientContent)
  return (
    <div
      className={` h-full ${
        column._type === "columnImage" ? "my-5px w-full" : "md:w-1/2"
      }`}
    >
      {column._type === "column" && clientContent && (
        <div className="p-15px md:p-20px">
          <RichText blocks={clientContent.content} />
        </div>
      )}
      {column._type === "columnImage" && (
        <figure className="relative w-full h-full">
          <div className="relative w-full h-full py-0 border-gray-200 md:border-l p-8px my-3px">
            <div className="relative w-full h-full aspect-w-3 aspect-h-2 md:aspect-w-none ">
              <div>
                <UtilImage
                  image={column.image.asset.gatsbyImageData}
                  className={`w-full h-full  object-cover `}
                ></UtilImage>
              </div>
            </div>
          </div>
        </figure>
      )}
    </div>
  )
}

export default AccordionColumn
