import React from "react"
import ProductGrid from "../product/ProductGrid"
import LinkPlus from "../link/LinkPlus"
const Products = ({ section }) => {
  const link =
    section.link?.linkObject?._type === "collection"
      ? `/collection/${section.link.linkObject.slug.current}`
      : section.link?.linkObject?._type === "page"
      ? `/${section.link.linkObject.slug.current}`
      : section.link?.linkObject?._type === "product"
      ? `/store/${section.link.linkObject.store.slug.current}`
      : section.link?._type === "aboutLink"
      ? `${section.link.slug.current}`
      : "/"
  return (
    <section className="bg-white">
      {section.sectionTitle && (
        <LinkPlus
          to={link}
          title={section.sectionTitle.title}
          className="          items-center
          p-15px md:px-20px md:pb-20px
          -mt-1.5
          text-large
          transform
          translate-y-px
          bg-white
          border-t md:border-t-0 border-b border-gray-200"
        />
      )}

      <ProductGrid products={section.products.products} />
    </section>
  )
}

export default Products
