import React from "react"
import LinkPlus from "../link/LinkPlus"
import RichText from "../text/RichText"
import { useMediaQuery } from "react-responsive"

const Copy = ({ section, clientSection }) => {
  const isLargeScreen = useMediaQuery({
    minWidth: clientSection ? clientSection.splitCopy.deviceWidth : 1400,
  })
  const link =
    section.link?.linkObject?._type === "collection"
      ? `/collection/${section.link.linkObject.slug.current}`
      : section.link?.linkObject?._type === "page"
      ? `/${section.link.linkObject.slug.current}`
      : section.link?.linkObject?._type === "product"
      ? `/store/${section.link.linkObject.store.slug.current}`
      : section.link?._type === "aboutLink"
      ? `${section.link.slug.current}`
      : "/"

  return (
    <section className="relative flex flex-col justify-between h-full pb-32 overflow-hidden bg-white wrap-sm">
      <div className="relative h-full bg-white text-large ">
        <div className="tall:pb-80  py-15px  md:py-20px copy-container">
          {isLargeScreen ? (
            <div className="flex justify-between ">
              <div className="w-1/2 pr-10">
                {clientSection &&
                  clientSection?.splitCopy?.firstCopy.map(child => {
                    return <RichText key={child._key} blocks={child} />
                  })}
              </div>
              <div className="w-1/2 pl-10">
                {clientSection &&
                  clientSection?.splitCopy?.secondCopy.map(child => {
                    return <RichText key={child._key} blocks={child} />
                  })}
              </div>
            </div>
          ) : (
            clientSection &&
            clientSection.copy.map(child => {
              return <RichText key={child._key} blocks={child} />
            })
          )}
        </div>
      </div>
      {section.link.title && (
        <LinkPlus
          to={link}
          title={section.link.title}
          className="absolute bottom-0 left-0 items-end w-full p-15px md:px-20px md:pb-20px"
        ></LinkPlus>
      )}
    </section>
  )
}

export default Copy
